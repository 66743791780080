import React from "react"
import Image from "../image"
import CustomLink from "./custom-link"
import { localizePath } from "@/utils/localize"
import RichText from "../sections/rich-text"


const Footer = ({ global }) => {
  return (
    <footer className="p-6 pt-12 bg-vsrc">
      <div className="flex flex-wrap text-white">
        <div className="flex flex-col w-6/12 lg:w-3/12 justify-center p-16">
          {global.footer.logo && (
            <Image media={global.footer.logo} className="h-auto w-20 object-contain" />
          )}
          <p className="mt-auto">{global.footer.copyright}</p>
        </div>
        <nav className="flex flex-wrap flex-row lg:gap-20 items-start w-6/12 lg:w-3/12 p-16 text-xl">
          <ul>
            {global.navbar.links.map(navLink => (
              <li key={navLink.id}>
              <CustomLink
                link={{
                  ...navLink,
                  url: `${localizePath({
                    isPreview: false,
                    // Remove the '/'
                    slug: navLink.url.slice(1),
                  })}`,
                }}
              >
                <div className="hover:text-gray-400 px-2 pb-2 font-semibold">
                  {navLink.text}
                </div>
              </CustomLink>
            </li>
            ))}
          </ul>
        </nav>
        <div className="w-6/12 lg:w-3/12 p-16">
          <RichText data={{content: global.footer.addressBox, style: 'text-white leading-5 flex flex-col space-y-2'}}></RichText>
        </div>
        <div className="w-6/12 lg:w-3/12 p-16">
          <RichText data={{content: global.footer.contactBox, style: 'text-white leading-5 flex flex-col space-y-2'}}></RichText>
        </div>
      </div>
    </footer>
  )
}

export default Footer
