import React from "react"
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import classNames from "classnames";

const RichText = ({ data }) => {
  return (
    <div className={classNames("container", data.style)}>
      <Markdown source={data.content} />
    </div>
  );
};

RichText.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    style: PropTypes.string,
  }),
};

export default RichText;
